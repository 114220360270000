
  import { Component } from 'vue-property-decorator'
  import { TimelineBase } from '@/components/core/view/TimelineBase'
  import { VRow } from 'vuetify/lib/components'

@Component({
  components: {
    VRow,
  },
})
  export default class GTimelineItemPhone extends TimelineBase {
    render (h) {
      return h(
        'p',
        {
          class: [
            'text-caption',
            'primary-text',
            'text--darken-4',
            'pa-0',
          ],
        },
        [
          h(
            'span',
            {
              class: 'font-weight-bold blue--text text--darken-3',
            },
            [this.label]
          ),
          ' ',
          h(
            'a',
            {
              attrs: {
                href: `tel:${this.value}`,
              },
            },
            [this.value]
          ),
        ]
      )
    }
  }
